import { Role } from '@/_helpers/role'

export default [
  {
    header: 'Finances',
    icon: 'BriefcaseIcon',
    children: [
      {
        icon: 'ShuffleIcon',
        title: 'CashFlow',
        route: 'CashFlow',
      },
      {
        icon: 'DollarSignIcon',
        title: 'Salaries payout',
        route: 'Salaries',
      },
    ],
    role: [Role.Admin],
  },
  {
    header: 'Work Pool',
    icon: 'UsersIcon',
    route: 'Clients',
    children: [
      {
        icon: 'CodepenIcon',
        title: 'Projects',
        route: 'Work-Pool-Projects',
      },
      {
        icon: 'ClipboardIcon',
        title: 'Contracts',
        route: 'Work-Pool-Contracts',
      },
      {
        icon: 'ClipboardIcon',
        title: 'Unconfirmed Contracts',
        route: 'Work-Pool-Unconfirmed-Contracts',
      },
    ],
    role: [Role.Admin],
  },
  {
    title: 'Comrades',
    icon: 'UsersIcon',
    route: 'Comrades',
    role: [Role.Admin],
  },
  {
    header: 'Reporting',
    icon: 'ClipboardIcon',
    role: [Role.Admin],
    children: [
      {
        title: 'Projects',
        route: 'Reports-Projects',
        icon: 'CodepenIcon',
      },
      {
        title: 'Comrades',
        route: 'Reports-Comrades',
        icon: 'UsersIcon',
      },
      {
        title: 'Breakdown',
        route: 'Reports-Workloads',
        icon: 'PieChartIcon',
      },
    ],
  },
  {
    title: 'Projects',
    route: 'Reports-Projects',
    icon: 'CodepenIcon',
    role: [Role.Manager]
  },
  {
    header: 'Interviews',
    icon: 'PhoneIcon',
    children: [
      {
        title: 'Interviews',
        route: 'Interviews',
        icon: 'PhoneIcon',
      },
      {
        title: 'Settings',
        route: 'InterviewsSettings',
        icon: 'SettingsIcon',
      },
    ],
    role: [Role.Admin],
  },
  {
    title: 'Settings',
    icon: 'SettingsIcon',
    route: 'Settings',
    role: [Role.Admin],
  },
  {
    title: 'Users',
    icon: 'UserIcon',
    route: 'Users',
    role: [Role.Admin],
  },
]
